<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submitNewCard)" class="Form">
      <div class="Form__wrapper">
        <h1 class="title-3">Payment information</h1>
        <p class="text-xlg text-semi">Complete the signup form</p>
        <formly-form :form="formDetails" :model="form" :fields="fields" tag="div" class="Form__group"></formly-form>
      </div>
      <button class="btn btn--primary btn--min">Save payment method</button>
   </form>
 </ValidationObserver>
</template>

<script>
import PaymentFormMixin from '@/mixins/PaymentForm';
export default {
  name: "PaymentInformation",
  mixins: [PaymentFormMixin],
  methods: {
    resolveCall() { 
      this.$router.push({name: 'subscription'})
    }
  }
}
</script>
