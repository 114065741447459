import api from '@/api/index'

const PaymentFormMixin = {
  data: function() {
    return {
      stripe: process.env.VUE_APP_STRIPE_KEY
        ? window.Stripe(process.env.VUE_APP_STRIPE_KEY)
        : null,
      cardElement: null,
      cardForm: {},
      formDetails: {},
      form: {
        holder: '',
        stripe_payment_method: '',
        address: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
      },
      fields: [
        {
          key: 'holder',
          type: 'input',
          rules: 'required',
          templateOptions: {
            type: 'text',
            label: 'Card Holder',
            layout: 'full',
          },
        },
        {
          key: 'card',
          type: 'card',
          rules: 'required',
          templateOptions: {
            layout: 'full',
          },
        },
        {
          key: 'address',
          type: 'input',
          rules: 'required',
          templateOptions: {
            type: 'text',
            label: 'Address',
            layout: 'full',
          },
        },
        {
          key: 'address_line_2',
          type: 'input',
          templateOptions: {
            type: 'text',
            label: 'Address Line 2',
            layout: 'full',
          },
        },
        {
          key: 'city',
          type: 'input',
          templateOptions: {
            type: 'text',
            label: 'City',
            layout: 'full',
          },
        },
        {
          key: 'state',
          type: 'input',
          templateOptions: {
            type: 'text',
            label: 'State',
            layout: 'half',
          },
        },
        {
          key: 'zip',
          type: 'input',
          templateOptions: {
            type: 'text',
            label: 'Postal Code',
            layout: 'half',
          },
        },
      ],
    }
  },
  mounted: function() {
    this.cardElement = this.createCardElement('#payment-card-element')
  },
  methods: {
    generateToken(callback) {
      return api.http
        .get('stripe/token')
        .then((response) => callback(response.data.clientSecret))
    },
    createCardElement(container) {
      if (!this.stripe) {
        throw 'Invalid Stripe Key/Secret'
      }

      var card = this.stripe.elements().create('card', {
        hidePostalCode: true,
        style: {
          base: {
            '::placeholder': {
              color: '#aab7c4',
            },
            fontFamily:
              'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
            color: '#495057',
            fontSize: '15px',
          },
        },
      })

      card.mount(container)
      return card
    },
    submitNewCard() {
      if (!this.$store.state.status.isVisible) {
        this.$store.dispatch('status/TOGGLE_LOADER', {
          visible: true,
          status: 'Adding card...',
        })
        const payload = {
          name: this.form.holder,
          address: {
            line1: this.form.address || '',
            line2: this.form.address_line_2 || '',
            city: this.form.city || '',
            state: this.form.state || '',
            postal_code: this.form.zip || '',
          },
        }
        this.generateToken((secret) => {
          this.stripe
            .handleCardSetup(secret, this.cardElement, {
              payment_method_data: {
                billing_details: payload,
              },
            })
            .then((response) => {
              if (response.error) {
                this.form.busy = false
                this.$store.dispatch('status/TOGGLE_LOADER', { visible: false })
                this.$notify({
                  group: 'general',
                  title: 'Please check your card details!',
                  type: 'error',
                })
              } else {
                this.cardElement.clear()
                let $store = this.$store
                this.$store
                  .dispatch(
                    'payments/ADD_PAYMENT_METHOD',
                    response.setupIntent.payment_method,
                    this,
                  )
                  .then(() => $store.dispatch('FETCH_USER'))
                  .then(() => this.resolveCall())
              }
            })
            .catch(() => {
              this.$notify({
                group: 'general',
                title: 'Error!',
                text: "Card wasn't added due to an error",
                type: 'error',
              })
              this.$store.dispatch('status/TOGGLE_LOADER', { visible: false })
            })
        })
      }
    },
    resolveCall() {},
  },
}

export default PaymentFormMixin
